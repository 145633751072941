<template>
  <div class="um-login">
    <div class="um-login__content">
      <h2 class="um-login__title">{{ cms.page.title }}</h2>
      <TzMarkdown
        class="um-login__sub-text"
        :content="cms.page.sub_text"
        v-if="cms.page.sub_text"
      />
      <div class="um-login__post-text">
        Don't have an account?
        <button v-touch:tap="handleOpenModifySearchModal">
          Join Here!
        </button>
      </div>
      <div
        class="overlay"
        :class="{ 'overlay--show': isSearchOpen }"
        v-touch:tap.self="closeModal"
      >
        <WlModalForm
          class="tzModal--search"
          v-if="isSearchOpen"
          :open="isSearchOpen"
          :close-modal="closeModal"
          :inputs="searchInputs"
          :modal-title="cms.page.search_modal.title"
          :confirm-button-text="cms.page.search_modal.confirm_button_text"
          :cancel-button-text="cms.page.search_modal.cancel_button_text"
          @wl-modal-input-focus="removeInputError"
          @wl-modal-form-confirm="handleConfirm"
          @wl-modal-form-cancel="handleCancelForm"
        />
      </div>
    </div>
    <form class="wl-form" v-on:submit="handleLogin($event)">
      <div class="wl-form__container">
        <h1 class="wl-form__title">{{ cms.page.login_title }}</h1>
        <TzMarkdown
          v-for="item in renderIfReturnArray({ condition: hasInvalidInput })"
          :key="item"
          class="form-message form-message--error"
          :content="errorText"
        />
        <div class="wl-form__wrapper">
          <div class="wl-form__form-group">
            <label class="wl-form__label">{{ cms.page.email_label }}</label>
            <input
              class="wl-form__input"
              :class="{ 'wl-form__input--invalid': invalidInput.email }"
              type="email"
              name="email"
              v-model="email"
              :placeholder="cms.page.email_label"
            />
          </div>

          <div class="wl-form__form-group">
            <label class="wl-form__label">{{ cms.page.password_label }}</label>
            <input
              class="wl-form__input"
              :class="{ 'wl-form__input--invalid': invalidInput.password }"
              type="password"
              name="password"
              v-model="password"
              :placeholder="cms.page.password_label"
            />
          </div>

          <label class="wl-form__form-group wl-form__form-group--checkbox">
            <input
              type="checkbox"
              name="remember"
              class="wl-form__input"
              v-model="rememberMe"
            />
            <span class="wl-form__label-text">{{
              cms.page.remember_label
            }}</span>
          </label>

          <a class="wl-form__link" :href="cms.page.forgot_link">{{
            cms.page.forgot_text
          }}</a>

          <button
            class="wl-form__button"
            :class="{ 'wl-form__button--disabled': isLoggingIn }"
            type="submit"
            :disabled="isLoggingIn"
          >
            <span class="wl-form__button-text">{{ cms.page.button_text }}</span>
          </button>
        </div>
        <div class="wl-form__link">
          <a :href="cms.page.link_url">{{ cms.page.link_text }}</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Meta from '@/assets/js/shared/misc/meta.mjs';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import WlModalForm from '@/components/shared/wl-modal-form';
import helpers from '@/assets/js/shared/misc/helpers';
import warningIcon from '@/assets/images/icon-exclamation.svg';
import resultHelper from '@/assets/js/resultHelper';
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray.js';

import getRegex from '@/assets/js/shared/helpers/getRegex';
const pageCms = require(`@/assets/cms/pages/login.json`);

export default {
  name: 'login',
  components: { TzMarkdown, WlModalForm },
  computed: {
    hasInvalidInput() {
      return this.invalidInput.email || this.invalidInput.password;
    }
  },
  data() {
    return {
      defaultErrorText:
        'Unable to login. Please verify your email address and password below.',
      email: null,
      errorText: null,
      isLoggingIn: false,
      invalidInput: {
        email: false,
        password: false
      },
      password: null,
      rememberMe: false,
      renderIfReturnArray,
      warningIcon,
      isSearchOpen: false,
      searchInputs: [
        {
          name: 'firstName',
          type: 'text',
          defaultValue: this.$route.query.firstName || '',
          placeholder: 'First Name',
          validationType: 'text',
          errorMsg: null
        },
        {
          name: 'middleName',
          type: 'text',
          defaultValue: '',
          placeholder: 'Middle Name',
          validationType: 'text'
        },
        {
          name: 'lastName',
          type: 'text',
          defaultValue: '',
          placeholder: 'Last Name',
          validationType: 'text',
          errorMsg: null
        },
        {
          name: 'city',
          type: 'text',
          defaultValue: '',
          placeholder: 'City',
          validationType: 'text'
        },
        {
          name: 'state',
          type: 'select',
          defaultValue: '',
          valueOptions: resultHelper.getStatesForDropdown()
        },
        {
          name: 'ages',
          type: 'select',
          defaultValue: '',
          valueOptions: [
            {
              value: '',
              name: 'All Ages'
            },
            {
              value: '18-30',
              name: '18-30'
            },
            {
              value: '31-40',
              name: '31-40'
            },
            {
              value: '41-50',
              name: '41-50'
            },
            {
              value: '51-60',
              name: '51-60'
            },
            {
              value: '61-110',
              name: '61-110'
            }
          ]
        }
      ]
    };
  },
  methods: {
    handleError(error) {
      this.isLoggingIn = false;
      this.errorText = error ? error : this.defaultErrorText;
    },
    handleSuccessfulLogin() {
      this.invalidInput.email = false;
      this.invalidInput.Password = false;
      this.isLoggingIn = false;
      if (this.rememberMe) {
        localStorage.setItem('rememberMe', this.email);
      }
      const { href } = this.$router.resolve({
        name: 'dashboard-reports'
      });
      location.href = href;
    },
    async handleLogin($event) {
      $event.preventDefault();
      this.isLoggingIn = true;
      this.invalidInput.email = false;
      this.invalidInput.password = false;
      this.validateEmailAndPassword();

      if (this.hasInvalidInput) {
        return;
      }

      /** Company/Testing emails will have company domain, all customers are unmask domain **/
      const domain =
        process.env.NODE_ENV === 'production'
          ? `${this.$config.website}.com`
          : this.$config.testingAccountDomain;

      /** Attempt to login **/
      try {
        const login = await this.$travellingSDK.AuthDomain.login(
          {
            email: this.email,
            password: this.password,
            domain: 'default'
          },
          domain
        );
        /** If call is successful, check for errors **/
        if (!login.msg || login.type) {
          let error = null;
          switch (login.type) {
            case 'locked':
              this.invalidInput.email = true;
              error = `This account has been locked.<br/>Please <a href="${this.cms.page.forgot_link}">reset your password</a> to login.`;
              break;
            case 'password-error':
              /** Invalid Password will highlight password input and change default error message **/
              this.invalidInput.password = true;
              error =
                'Password does not meet requirements. Please verify and resubmit.';
              break;
            default:
              /** All other errors will highlight both inputs **/
              this.invalidInput.email = true;
              this.invalidInput.password = true;
          }
          /** Display error to user **/
          this.handleError(error);
          return;
        }
      } catch (err) {
        this.$bugsnag.notify(err);
        /** On failure to hit API, display message with contact info **/
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.handleError();
        return;
      }

      this.handleSuccessfulLogin();
    },
    validateEmailAndPassword() {
      if (!this.email && !this.password) {
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.handleError('Please enter your email address and password below.');
        return;
      }

      if (
        !this.email ||
        !getRegex({ regexType: 'emailRegex' }).test(this.email)
      ) {
        this.invalidInput.email = true;
        this.handleError('Please enter a valid email address');
        return;
      }

      if (!this.password) {
        this.invalidInput.password = true;
        this.handleError('Please enter a valid password');
      }
    },
    removeInputError(inputIndex) {
      this.searchInputs[inputIndex].errorMsg = null;
    },
    resetNameInputErrors() {
      this.searchInputs[0].errorMsg = null;
      this.searchInputs[2].errorMsg = null;
    },
    handleOpenModifySearchModal() {
      this.isSearchOpen = true;
    },
    handleConfirm() {
      const firstName = this.searchInputs[0].defaultValue;
      const middleName = this.searchInputs[1].defaultValue;
      const lastName = this.searchInputs[2].defaultValue;
      const city = this.searchInputs[3].defaultValue;
      const state = this.searchInputs[4].defaultValue;
      const age = this.searchInputs[5].defaultValue.split('-');
      const minAge = age[0];
      const maxAge = age[1];
      const ageDefaultValue = minAge && maxAge ? `${minAge}-${maxAge}` : '';

      const aid = 25;
      const tid = '';
      const sid = '';
      const hitid = '';
      const iv = '';
      const obcid = '';

      if (!firstName) {
        this.searchInputs[0].errorMsg = this.isMobile
          ? 'Required'
          : 'First Name is Required';
      }
      if (!lastName) {
        this.searchInputs[2].errorMsg = this.isMobile
          ? 'Required'
          : 'Last Name is Required';
      }
      if (!firstName || !lastName) {
        return;
      }
      if (city && !state) {
        this.searchInputs[4].errorMsg = this.isMobile
          ? 'Required'
          : 'Please select a state';
        return;
      }

      sessionStorage.clear();
      this.$router.push(
        {
          name: 'flow.searching',
          query: {
            firstName,
            middleName,
            lastName,
            city,
            state,
            age: ageDefaultValue,
            aid,
            tid,
            sid,
            hitid,
            iv,
            obcid
          },
          params: { landingID: pageCms.landingID }
        },
        () => {
          location.reload();
        }
      );
    },
    handleCancelForm() {
      this.isSearchOpen = false;
      this.resetNameInputErrors();
    },
    closeModal() {
      if (this.isSearchOpen) {
        this.isSearchOpen = false;
        this.openModifySearchModal = false;
        this.resetNameInputErrors();
      }
    },
    updatePrefillState() {
      const parentData = this.$parent.$parent.$data;
      const geolocateState = parentData.prefillFields.state;
      const dataObj = this.searchInputs.find(obj => obj.name === 'state');
      if (geolocateState) {
        dataObj.defaultValue = geolocateState;
      }
    }
  },
  mounted() {
    /** Pre-fill user's email in the form if "Remember me" is checked **/
    if (!localStorage.rememberMe) {
      return {};
    }
    const userEmail = localStorage.getItem('rememberMe');
    this.email = userEmail;
    if (userEmail) {
      this.rememberMe = true;
    }
  },
  updated() {
    /** manually adding state prefill as parent value is set asynchronously and does not get passed down **/
    this.updatePrefillState();
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms,
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
